/* ##remfixer: 1080 */
.CommentAppList {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #f2f2f2;
}
.CommentAppList .NavBar {
  margin-bottom: 0;
}
.CommentAppList .block_list {
  padding: 0;
}

/* .content {
  width: 100%;
  .title {
    width: 100%;
    height: 295px;
    background: url("../assets/images/about_title.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 28px;
  }
  .text {
    padding: 0 66px;
    margin-bottom: 40px;
    float: left;
    &_title {
      width: 100%;
      text-align: center;
      font-size: 60px;
      color: rgba(11, 11, 11, 0.9);
      line-height: 72px;
      font-weight: 500;
      margin-bottom: 27px;
    }
    &_txt {
      line-height: 75px;
      font-size: 48px;
      color: rgba(51, 51, 51, 0.8);
      letter-spacing: 0;
      margin-bottom: 81px;
    }
  }
} */

/*# sourceMappingURL=CommentaAppList-vue.d4d99748.css.map*/